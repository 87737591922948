import { useGlobalContext } from '@honeycomb/data';
import { DialogProps, TypographyStyler, Dialog, DialogContent, DialogHeader } from '@honeycomb/ui-core';

export function AtolDialog(props: DialogProps): React.JSX.Element {
    const {
        resourceStrings: { HC_MASTHEAD_ATOLPROTECTION_DESC, HC_MASTHEAD_ATOLPROTECTION_TITLE },
    } = useGlobalContext();

    return (
        <Dialog size="s" {...props}>
            <DialogHeader withCloseButton={true} border={false}>
                {HC_MASTHEAD_ATOLPROTECTION_TITLE}
            </DialogHeader>
            <DialogContent>
                <TypographyStyler pt={2} py={4}>
                    {HC_MASTHEAD_ATOLPROTECTION_DESC}
                </TypographyStyler>
            </DialogContent>
        </Dialog>
    );
}
